import React from "react";
/** Importing functional components */
import DefaultPage from "../layout/DefaultPage";
import { graphql } from "gatsby";
import CarouselPost from "../components/CarouselPost";
/** Importing styled components */

import {
  BlogLayout,
  GeneralContainer,
  PostRow,
  PostCover,
  H1,
  Paragraph,
  FluidImageGallery,
  RootBorder
} from "../components/StyledComponent";

import { Col } from "react-flexbox-grid";

const BlogPage = ({ data: { getData } }) => {
  const { frontmatter } = getData;
  return (
    <DefaultPage pageTitle={frontmatter.title}>
      <BlogLayout>
        <RootBorder />
        <PostCover>
          <CarouselPost height="100%" slidesData={frontmatter} />
        </PostCover>
        <GeneralContainer minHeight="calc(100vh - 28rem)">
          <PostRow>
            <Col xs={12} md={8}>
              <PostRow>
                <Col xs={12}>
                  <H1 color="var(--secondary)">{frontmatter.title}</H1>
                </Col>
              </PostRow>
              <PostRow>
                <Col xs={12}>
                  <Paragraph color="var(--black)">
                    {frontmatter.description}
                  </Paragraph>
                </Col>
              </PostRow>
              <PostRow>
                <Col xs={12}>
                  <div className="html__container" dangerouslySetInnerHTML={{ __html: getData.html }}></div>
                </Col>
              </PostRow>
            </Col>
            <Col xs={12} md={4}>
              <PostRow>
                {getData.frontmatter.gallery ? (
                  getData.frontmatter.gallery.map((el, i) => {
                    if (i === 0) {
                      return (
                        <Col
                          xs={12}
                          md={12}
                          key={i}
                          style={{ marginBottom: "1rem" }}
                        >
                          <FluidImageGallery
                            key={i}
                            maxWidth="38rem"
                            maxHeight="15rem"
                            fluid={el.image.childImageSharp.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt=""
                          />
                        </Col>
                      );
                    } else {
                      return (
                        <Col
                          xs={6}
                          md={6}
                          key={i}
                          style={{ marginBottom: "1rem" }}
                        >
                          <FluidImageGallery
                            maxWidth="38rem"
                            key={i}
                            fluid={el.image.childImageSharp.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt=""
                          />
                        </Col>
                      );
                    }
                  })
                ) : (
                  <Col xs={12} md={12}>
                    <p>No hay galerìa</p>
                  </Col>
                )}
              </PostRow>
            </Col>
          </PostRow>
        </GeneralContainer>
      </BlogLayout>
    </DefaultPage>
  );
};

export default BlogPage;

export const postQuery = graphql`
  query getPostData($id: String!) {
    getData: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        iframeVideo
        title
        description
        date
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        cover {
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`;
